import React, { useRef, useEffect } from 'react'
import { twcx } from 'helpers/twcx'

import s from './Overlay.module.css'


export type OverlayProps = {
  className?: string
  withAnimation?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Overlay: React.CFC<OverlayProps> = ({ children, className, onClick, withAnimation = true }) => {
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    // ATTN this workaround required to fix transition animation glitch (elements jumping from top to down when moving)
    //  timeout means that animation is finished
    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.classList.add(s.mounted)
      }
    }, 400)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <div ref={ref} className={twcx(s.overlay, withAnimation && s.animated, 'inset-0 bg-black/65', className)} onClick={onClick}>
      {children}
    </div>
  )
}


export default Overlay
