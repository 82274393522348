import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'


type PageLayoutProps = {
  className?: string
  withPaddingTop?: boolean
  withPaddingBottom?: boolean
  'data-testid'?: string
}

const PageLayout: React.CFC<PageLayoutProps> = (props) => {
  const { children, className, withPaddingTop = true, withPaddingBottom = true, 'data-testid': dataTestId } = props

  const { isMobile } = useDevice()

  const topClassName = isMobile ? 'pt-40' : 'pt-48'
  const bottomClassName = isMobile ? 'pb-64' : 'pb-80'

  const rootClassName = cx(className, {
    [topClassName]: withPaddingTop,
    [bottomClassName]: withPaddingBottom,
  })

  return (
    <div className={rootClassName} data-testid={dataTestId}>{children}</div>
  )
}


export default PageLayout
